// @ts-nocheck

import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'react-tooltip/dist/react-tooltip.css'

import React, { Fragment, lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useGetBillingClientByIdMutation } from './api/billingUserApi'
import {
  useGetCriticalUnreadNotificationsMutation,
  useGetUnreadNotificationsMutation,
  useGetUserDataMutation,
  useMarkNotificationAsReadMutation
} from './api/userApi'
import earth from './assets/icons/earth.svg'
import { Alert } from './components/alert'
import { AnalyticsLayout } from './components/analyticsLayout/index'
import { Cookie } from './components/cookie/Cookie'
import { BACKEND_CODES } from './constants/backendCodes'
import { ROUTES } from './constants/routes'
import { AnalyticsProvider } from './hooks/useAnalytics'
import { useAuth } from './hooks/useAuth'
import { Analytics } from './pages/analytics/Analytics'
import { AnalyticsBI } from './pages/analyticsBI/AnalyticsBI'
import Appeals from './pages/appeals'
import { Contacts } from './pages/contacts/Contacts'
import Documentation from './pages/documentation'
import Home from './pages/home'
import InternalLogin from './pages/internalLogin'
import KnowledgeBase from './pages/knowledgeBase'
import Metrika from './pages/metrika'
import Notifications from './pages/notifications'
import OperationsHistory from './pages/operationsHistory'
import Settings from './pages/settings'
import Tariffs from './pages/tariffs'
import { selectLogin } from './store/loginSlice'
import { setNotificationAlert, setNotifications } from './store/notificationsSlise'
import { getCryticalNotifications } from './store/notificationsSlise'
import { checkIsNotifications } from './store/notificationsSlise'
import { checkIsNotificationAlert } from './store/notificationsSlise'
import { productFrom } from './store/redirectSlice'
import { selectProductFrom } from './store/redirectSlice'
import { selectUserBilling } from './store/userBillingSlice'
import { setABValue } from './store/userBillingSlice'
import { saveBillingSelectedDealer } from './store/userBillingSlice'
import { Button } from './ui/button'
import { Image } from './ui/image'
import { Loader } from './ui/loader'
import { Popup } from './ui/popup/Popup'
import { ImageModal, Text, TitlePopup } from './ui/popup/style'
import { getLocalStorageValue, setLocalStorageValue } from './utils/localStorageHelpers'
import { removeLocalStorageValue } from './utils/localStorageHelpers'

const NotProtectedLayout = lazy(() => import('./pages/notProtectedLayout'))
const ProtectedLayout = lazy(() => import('./pages/protectedLayout'))

const Registration = lazy(() => import('./pages/registartion'))
const Auth = lazy(() => import('./pages/authorization'))
const PasswordReset = lazy(() => import('./pages/passwordReset'))
const PasswordRestore = lazy(() => import('./pages/passwordRestore'))

const MainPage = lazy(() => import('./pages/mainPage'))
const LogoutAndRedirectInTrackerPage = lazy(() => import('./pages/logoutAndRedirectInTracker'))

const Page404 = lazy(() => import('./pages/page404'))

function App() {
  const {
    billingSelectedDealer,
    billingSelectedDealerId,
    billingClientInfo,
    isClientInfoResult,
    isSelectedDealerResult
  } = useSelector(selectUserBilling)

  const [getCriticalUnreadNotifications, { data: notifications, error }] = useGetCriticalUnreadNotificationsMutation()
  const [getlUnreadNotifications, { data: unreadNotifications }] = useGetUnreadNotificationsMutation()
  const [isDisablingModules, setIsDisablingModules] = useState<boolean>(false)
  const [
    getUserData,
    {
      isLoading: isLoadingUserData,
      data: userData,
      isError: isErrorUserData,
      error: userDataError,
      isSuccess: isSuccessUserData
    }
  ] = useGetUserDataMutation()
  const [
    getBillingClientById,
    {
      isLoading: isLoadingBillingUserById,
      data: billingClientById,
      isError: isErrorBillingUserById,
      error: billingUserByIdError,
      isSuccess: isSuccessBillingUserById
    }
  ] = useGetBillingClientByIdMutation()

  const [markNotificationAsRead, { isLoading: isMarkingRead }] = useMarkNotificationAsReadMutation()

  const [isShowCookie, setIsShowCookie] = useState(getLocalStorageValue('isShowCookie'))
  // @ts-ignore
  const { setUserData, logout } = useAuth()
  const dispatch = useDispatch()

  const cryticalNotificationsSelector = useSelector(getCryticalNotifications)
  const isNoCryticalNotifications = useSelector(checkIsNotifications)

  const {
    attributes: { auth_token, refresh_token }
  } = useSelector(selectLogin)

  const [notificationStack, setNotificationStack] = useState<CriticalUnreadNotificationsResponseType['data']>([])

  const navigate = useNavigate()
  const location = useLocation()

  const isNotificationAlert = useSelector(checkIsNotificationAlert)

  if (location.pathname === '/login') {
    navigate('/')
  }

  useLayoutEffect(() => {
    ;(async () => {
      if (!auth_token && !refresh_token) {
        logout()
      } else {
        await getUserData()
        setInterval(async () => {
          await getlUnreadNotifications()
        }, 300000)
      }
    })()
  }, [auth_token, refresh_token])

  useEffect(() => {
    if (userData && !userDataError) {
      setUserData(userData)
    }
  }, [userData, userDataError])

  useEffect(() => {
    if (isErrorUserData && userDataError?.status !== BACKEND_CODES?.accsess_token_expired) {
      logout()
    }
  }, [isErrorUserData])

  useEffect(() => {
    dispatch(setNotifications(!!unreadNotifications?.data?.count))
  }, [unreadNotifications])

  useEffect(() => {
    if (billingSelectedDealer?.id) {
      if (billingSelectedDealer?.is_plans_enabled) {
        dispatch(setABValue(billingSelectedDealer?.plan?.test_group_name))
      } else {
        dispatch(setABValue('0'))
      }

      if (!billingSelectedDealer?.is_plans_accepted && billingSelectedDealer?.is_plans_enabled) {
        navigate('/tariffs')
      }
    }
  }, [billingSelectedDealer])

  useEffect(() => {
    const queryString = window.location.search

    // Используем URLSearchParams для удобного доступа к параметрам
    const urlParams = new URLSearchParams(queryString)

    // Получаем значение конкретного параметра
    const myParam = urlParams.get('from') // 'myParam' - имя вашего параметра
    dispatch(productFrom(myParam))
  }, [])

  useEffect(() => {
    if (getLocalStorageValue('isShowCookie') === null) {
      setLocalStorageValue('isShowCookie', true)
      setIsShowCookie(true)
    }
  }, [isShowCookie])

  useEffect(() => {
    if (notifications) {
      // @ts-ignore
      setNotificationStack(notifications?.data)
    }
  }, [notifications])

  useEffect(() => {
    ;(async () => {
      if (location.pathname !== '/' && userData) {
        await getCriticalUnreadNotifications()
        await getlUnreadNotifications()
      }
    })()
  }, [navigate, userData, cryticalNotificationsSelector])

  useEffect(() => {
    if (location.pathname === '/' && userData) {
      navigate('/home', { replace: true })
    }
  }, [userData])

  useEffect(() => {
    ;(async () => {
      if (!isNoCryticalNotifications && userData) {
        await getCriticalUnreadNotifications()
        await getlUnreadNotifications()
      }
    })()
  }, [isNoCryticalNotifications])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        await getBillingClientById({ id: billingSelectedDealerId })
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    if (billingClientById?.id) {
      dispatch(saveBillingSelectedDealer(billingClientById))
    }
  }, [billingClientById])

  const handleMarkAsRead = async (notificationId: string) => {
    // @ts-ignore
    await markNotificationAsRead({ notificationId })
    // @ts-ignore
    setNotificationStack((prevStack) => prevStack?.filter((n) => n?.id !== notificationId))

    await getlUnreadNotifications()
  }

  const currentNotification = notificationStack[0]

  if (isLoadingUserData) {
    return <Loader />
  }

  return (
    <Fragment>
      {isShowCookie && <Cookie setIsShowCookie={setIsShowCookie} />}
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Auth />
            </Suspense>
          }
        ></Route>
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <NotProtectedLayout />
            </Suspense>
          }
        >
          <Route
            path={ROUTES?.registration}
            element={
              <Suspense fallback={<Loader />}>
                <Registration />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.login}
            element={
              <Suspense fallback={<Loader />}>
                <Auth />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.internalLogin}
            element={
              <Suspense fallback={<Loader />}>
                <InternalLogin />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.resetPassword}
            element={
              <Suspense fallback={<Loader />}>
                <PasswordReset />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.restorePassword}
            element={
              <Suspense fallback={<Loader />}>
                <PasswordRestore />
              </Suspense>
            }
          />
        </Route>

        <Route
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedLayout />
            </Suspense>
          }
        >
          <Route
            path={ROUTES?.dashboard}
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.appeals}
            element={
              <Suspense fallback={<Loader />}>
                <Appeals />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.operationsHistory}
            element={
              <Suspense fallback={<Loader />}>
                <OperationsHistory />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.documents}
            element={
              <Suspense fallback={<Loader />}>
                <Documentation />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.knowledgeBase}
            element={
              <Suspense fallback={<Loader />}>
                <KnowledgeBase />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.settings}
            element={
              <Suspense fallback={<Loader />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.contacts}
            element={
              <Suspense fallback={<Loader />}>
                <Contacts />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.tariffs}
            element={
              <Suspense fallback={<Loader />}>
                <Tariffs />
              </Suspense>
            }
          />
          <Route
            path={ROUTES?.notifications}
            element={
              <Suspense fallback={<Loader />}>
                <Notifications />
              </Suspense>
            }
          />
          <Route
            path="analytics/*"
            element={
              <Suspense fallback={<Loader />}>
                <AnalyticsProvider>
                  <AnalyticsLayout />
                </AnalyticsProvider>
              </Suspense>
            }
          >
            <Route
              path="graphics"
              element={
                <Suspense fallback={<Loader />}>
                  <Analytics />
                </Suspense>
              }
            />
            <Route
              path="metrika"
              element={
                <Suspense fallback={<Loader />}>
                  <Metrika />
                </Suspense>
              }
            />
            <Route
              path="bi"
              element={
                <Suspense fallback={<Loader />}>
                  <AnalyticsBI />
                </Suspense>
              }
            />
          </Route>
        </Route>

        {/* Общие роуты */}
        <Route
          path={ROUTES?.logoutAndRedirectInTracker}
          element={
            <Suspense fallback={<Loader />}>
              <LogoutAndRedirectInTrackerPage />
            </Suspense>
          }
        />
        <Route
          path={'*'}
          element={
            <Suspense fallback={<Loader />}>
              <Page404 />
            </Suspense>
          }
        />
      </Routes>
      {/*Все Важные Уведомления*/}
      {currentNotification && (
        <Popup
          className={'modal-notifications'}
          closePopup={setIsDisablingModules}
          background={true}
          isDanger={true}
          isHidden={true}
        >
          <ImageModal>
            <Image src={earth} alt="earth" />
          </ImageModal>
          <TitlePopup size={'small'}>{currentNotification?.attributes?.header}</TitlePopup>
          <Text>
            <div dangerouslySetInnerHTML={{ __html: currentNotification?.attributes?.body }} />
          </Text>
          <Button
            onClick={() => {
              handleMarkAsRead(currentNotification?.id)
            }}
          >
            Я ознакомлен
          </Button>
        </Popup>
      )}
      {isNotificationAlert &&
        location.pathname !== '/' &&
        location.pathname !== '/registration' &&
        location.pathname !== '/restore_password' && (
          <Alert theme="error">
            {{
              text: 'Доступ в раздел или к некоторым блокам страницы запрещен. Обновите страницу или <a href="mailto:support.udpauto.ru" target="_blank">сообщите об ошибке</a>'
            }}
          </Alert>
        )}
    </Fragment>
  )
}

export default App
