import { useEffect, useState } from 'react'
import { BiRuble } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import {
  useAcceptPlanMutation,
  useDeactivateLicenseMutation,
  useGetAddonPackagesMutation,
  useGetClientPlansFeaturesMutation,
  useGetClientPlansMutation,
  useGetSeoPlansMutation,
  useGetSupportPlansMutation,
  useSetNewServiceMutation
} from '../../api/tariffsApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import { Container } from '../../assets/styles/global'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { Popup } from '../../ui/popup/Popup'
import { Text, TitlePopup } from '../../ui/popup/style'
import { Toggle } from '../../ui/toggle'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import Arrow from './../../assets/icons/arrowGray.svg'
import infoIcon from './../../assets/icons/i.svg'
import SuccessCheck from './../../assets/icons/successCheck.svg'
import TariffsBaseIcon from './../../assets/images/tariffs/tariffs_base.png'
import TariffsLiteIcon from './../../assets/images/tariffs/tariffs_lite.png'
import TariffsOptimalIcon from './../../assets/images/tariffs/tariffs_optimal.png'
import TariffsPremiumIcon from './../../assets/images/tariffs/tariffs_premium.png'
import TariffsStandartIcon from './../../assets/images/tariffs/tariffs_standart.png'
import {
  ArchiveGroup,
  CardsContainer,
  FullContainer,
  LicenseContainer,
  LicenseGroup,
  MediumTitle,
  ModalButtonGroup,
  PacksContainer,
  PacksGroup,
  PacksPrice,
  PacksPriceGroup,
  PacksPrices,
  PreSelectBlock,
  SeoContainer,
  SeoDesktop,
  SeoGroup,
  SeoInfo,
  SeoInfoGroup,
  SmallText,
  SubTitle,
  SupportDesktop,
  TariffsCard,
  TariffsDesktop,
  TariffsTitle,
  TextPrice,
  ToggleBlock,
  ToggleMath,
  ToggleMore,
  TogglesContainer,
  TooltipGroup
} from './style'

export const Tariffs = () => {
  const [getClientPlans, { data: clientPlans }] = useGetClientPlansMutation()
  const [getClientPlansFeatures, { data: clientPlansFeatures }] = useGetClientPlansFeaturesMutation()
  const [getAddonPackages, { data: addonPackages }] = useGetAddonPackagesMutation()
  const [getSupportPlans, { data: supportPlans }] = useGetSupportPlansMutation()
  const [getSeoPlans, { data: seoPlans }] = useGetSeoPlansMutation()
  const [setNewService, { data: serviceRequest, isLoading: isLoadingNewService, isSuccess: successResponse }] =
    useSetNewServiceMutation()
  const [acceptPlan, { data: acceptPlanResult, isSuccess: successResponsePlan }] = useAcceptPlanMutation()
  const [deactivateLicense, { data: deactivate }] = useDeactivateLicenseMutation()
  const { billingSelectedDealer } = useSelector(selectUserBilling)
  const [isPreSelection, setPreselection] = useState<boolean>(true)
  const [preSelectedTariffs, setPreSelectedTariffs] = useState<any>({
    plan_id: '',
    support_plan_id: '',
    seo_plan_id: '',
    addon_package_ids: []
  })

  const [modifiedClientPlans, setModifiedClientPlans] = useState<any[]>([])
  const [modifiedSupportPlans, setModifiedSupportPlans] = useState<any[]>()
  const [modifiedSeoPlans, setModifiedSeoPlans] = useState<any[]>()

  const [currentTariffsSliderIndex, setCurrentTariffsSliderIndex] = useState<number>(0)
  const [currentSeoSliderIndex, setCurrentSeoSliderIndex] = useState<number>(0)
  const [currentSupportSliderIndex, setCurrentSupportSliderIndex] = useState<number>(0)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const [isShowedSeoPlans, setShowedSeoPlans] = useState(false)
  const [isShowedSupportPlans, setShowedSupportPlans] = useState(false)
  const [isShowedTariffs, setShowedTariffs] = useState(true)

  const [defaultVisibleElements, setDefaultVisibleElements] = useState(0)

  const [isPopupOpen, setPopupOpen] = useState<boolean>(false)

  const [activeIndexTariffPlan, setActiveIndexTariffPlan] = useState<number>(0)
  const [activeIndexSeoPlan, setActiveIndexSeoPlan] = useState<number>(0)
  const [activeIndexSupportPlan, setActiveIndexSupportPlan] = useState<number>(0)

  const [modifiedClientPlansFeatures, setModifiedClientPlansFeatures] = useState<any>([])
  const [modalType, setModalType] = useState<string>('')

  const [activeModalElement, setActiveModalElement] = useState<any>()

  const tariffsIcons = [TariffsBaseIcon, TariffsLiteIcon, TariffsStandartIcon, TariffsOptimalIcon, TariffsPremiumIcon]

  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const userType = getLocalStorageValue('user')?.attributes?.type

  enum TariffsType {
    PLAN_ID = 'plan_id',
    SUPPORT_PLAN_ID = 'support_plan_id',
    SEO_PLAN_ID = 'seo_plan_id',
    ADDON_PACKAGE_IDS = 'addon_package_ids'
  }

  const settingsTariffs = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    afterChange: (current: number) => {
      setCurrentTariffsSliderIndex(current)
    }
  }

  const settingsSEO = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    afterChange: (current: number) => {
      setCurrentSeoSliderIndex(current)
    }
  }

  const settingsSupport = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    afterChange: (current: number) => {
      setCurrentSupportSliderIndex(current)
    }
  }

  const settingsAddons = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    slidesToScroll: 1
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer?.id) {
        setModifiedClientPlans([])
        setShowedTariffs(false)
        await getClientPlans({ id: billingSelectedDealer.id })
        await getAddonPackages({ id: billingSelectedDealer.id })
        await getSupportPlans({ id: billingSelectedDealer.id })
        await getSeoPlans({ id: billingSelectedDealer.id })

        setPreselection(billingSelectedDealer?.is_plans_accepted)

        if (!billingSelectedDealer?.is_plans_accepted) {
          setModalType('archive')
          setPopupOpen(true)
        }
      }
    })()
  }, [billingSelectedDealer])

  useEffect(() => {
    if (clientPlansFeatures?.items) {
      const { items } = clientPlansFeatures ?? []
      const array: any[] = []

      if (defaultVisibleElements === 0) {
        setDefaultVisibleElements(items.length)
      }

      items?.forEach((item: any) => {
        array.push(item)
      })

      setModifiedClientPlansFeatures(array)

      const modified = clientPlans?.map((plan: any) => {
        const result: any[] = []

        array?.forEach((feature: any) => {
          feature?.plans?.forEach((el: any) => {
            if (el.plan_id === plan.id) {
              result.push({ ...feature, feature_description: el.feature_description })
            }
          })
        })

        return result
      })

      setModifiedClientPlans(modified)
    }
  }, [clientPlansFeatures])

  useEffect(() => {
    const modified = supportPlans?.map((plan: any) => plan.services)

    setModifiedSupportPlans(modified)
  }, [supportPlans])

  useEffect(() => {
    const modified = seoPlans?.map((plan: any) => plan.services)

    setModifiedSeoPlans(modified)

    if (billingSelectedDealer?.id) {
      setPreSelectedTariffs({
        [TariffsType.PLAN_ID]: billingSelectedDealer.plan.plan_id,
        [TariffsType.SUPPORT_PLAN_ID]: billingSelectedDealer.plan.support_plan_id,
        [TariffsType.ADDON_PACKAGE_IDS]: billingSelectedDealer.plan.addon_package_ids,
        [TariffsType.SEO_PLAN_ID]: billingSelectedDealer.plan.seo_plan_id
      })
    }
  }, [seoPlans])

  useEffect(() => {
    if (successResponse) {
      setModalContentByType('success')
    }
    if (successResponsePlan) {
      setPreselection(true)
    }
  }, [successResponse, successResponsePlan])

  const getMoreTariffs = async () => {
    const params = `?page[number]=1&page[size]=15`
    await getClientPlansFeatures({ id: billingSelectedDealer?.id, params })
    setShowedTariffs(true)
    if (userType !== 'internal') {
      sendUserClickEvent('tariffs_click_more_license')
    }
  }

  const setModalContentByType = (type: string, activeEl?: any) => {
    setModalType(type)
    setActiveModalElement(activeEl)

    setPopupOpen(true)
  }

  const setService = async (entity_type: string, entity_id: string) => {
    await setNewService({ client_id: billingSelectedDealer?.id, entity_type, entity_id })
  }

  const deactivateLicenseHandler = async (client_id: string | number, entity_id: string) => {
    await deactivateLicense({ client_id, entity_id })
    setPopupOpen(false)
  }

  const handlerSetPreselectedTariffs = (type: string, id: string) => {
    switch (type) {
      case TariffsType.PLAN_ID:
        setPreSelectedTariffs({
          ...preSelectedTariffs,
          [TariffsType.PLAN_ID]: preSelectedTariffs[TariffsType.PLAN_ID] === id ? '' : id
        })
        break
      case TariffsType.SUPPORT_PLAN_ID:
        setPreSelectedTariffs({
          ...preSelectedTariffs,
          [TariffsType.SUPPORT_PLAN_ID]: preSelectedTariffs[TariffsType.SUPPORT_PLAN_ID] === id ? '' : id
        })
        break
      case TariffsType.SEO_PLAN_ID:
        setPreSelectedTariffs({
          ...preSelectedTariffs,
          [TariffsType.SEO_PLAN_ID]: preSelectedTariffs[TariffsType.SEO_PLAN_ID] === id ? '' : id
        })
        break
      case TariffsType.ADDON_PACKAGE_IDS:
        const addonPaccageIds = Object.values(preSelectedTariffs[TariffsType.ADDON_PACKAGE_IDS])

        if (preSelectedTariffs[TariffsType.ADDON_PACKAGE_IDS]?.find((tariff: any) => tariff === id)) {
          addonPaccageIds.splice(addonPaccageIds.indexOf(id), 1)
        } else {
          addonPaccageIds.push(id)
        }

        setPreSelectedTariffs({
          ...preSelectedTariffs,
          [TariffsType.ADDON_PACKAGE_IDS]: addonPaccageIds
        })
        break
    }
  }

  const includeNewTariffs = async () => {
    await acceptPlan({ client_id: billingSelectedDealer?.id, body: preSelectedTariffs })

    setModalContentByType('success')
  }

  const wrapInSpans = (inputString: string): JSX.Element[] => {
    const splitArray = inputString.split(';').filter((item) => item.trim() !== '')

    return splitArray.map((item, index) => <span key={index}>{item.trim()}</span>)
  }
  return (
    <Container className="container-tariffs">
      {!isPreSelection ? (
        <PreSelectBlock>
          Подтвердите подключение предложенных ниже услуг
          <Button
            style={{ borderColor: '#F18801', background: '#F18801', paddingTop: '5px', paddingBottom: '5px' }}
            onClick={() => setModalContentByType('archive')}
          >
            Подключить
          </Button>
        </PreSelectBlock>
      ) : null}
      {clientPlans?.length ? (
        <div>
          <TariffsTitle>Тарифы лицензий</TariffsTitle>
          {isMobile ? (
            <div>
              <CardsContainer className="card-container-tariffs">
                {clientPlans?.length ? (
                  <Slider {...settingsTariffs}>
                    {clientPlans?.map((plan: any, i: number) => {
                      if (billingSelectedDealer?.plan.plan_id === plan.id && activeIndexTariffPlan !== i) {
                        setActiveIndexTariffPlan(i)
                      }

                      return (
                        <TariffsCard bgImage={tariffsIcons[i]} key={plan.id}>
                          <SubTitle>
                            {plan.name}
                            <TooltipGroup>
                              <p>{plan.description}</p>
                              <img src={infoIcon} alt="" />
                            </TooltipGroup>
                          </SubTitle>
                          {billingSelectedDealer?.plan.plan_id === plan.id ? (
                            <TextPrice className="tariffs-text">
                              {Number(plan.price_per_period).toLocaleString('ru')} <BiRuble />
                            </TextPrice>
                          ) : null}
                          {plan.price_per_period > clientPlans[activeIndexTariffPlan]?.price_per_period &&
                          clientPlans[activeIndexTariffPlan]?.id === billingSelectedDealer?.plan.plan_id ? (
                            <TextPrice className="tariffs-text">
                              +
                              {Number(
                                plan.price_per_period - clientPlans[activeIndexTariffPlan]?.price_per_period
                              ).toLocaleString('ru')}{' '}
                              <BiRuble />
                            </TextPrice>
                          ) : null}
                          <Button
                            variant={billingSelectedDealer?.plan.plan_id === plan.id ? 'default' : 'secondary'}
                            size="medium"
                            style={{
                              pointerEvents: billingSelectedDealer?.plan.plan_id === plan.id ? 'none' : 'inherit'
                            }}
                            onClick={() => {
                              if (!isPreSelection) {
                                handlerSetPreselectedTariffs(TariffsType.PLAN_ID, plan.id)
                                if (userType !== 'internal') {
                                  sendUserClickEvent(`tariffs_click_license_preselection${plan.code}`)
                                }
                              } else if (
                                plan.price_per_period > clientPlans[activeIndexTariffPlan]?.price_per_period &&
                                clientPlans[activeIndexTariffPlan]?.id === billingSelectedDealer?.plan.plan_id
                              ) {
                                setModalContentByType('tariff', plan)
                                if (userType !== 'internal') {
                                  sendUserClickEvent(`tariffs_click_license_${plan.code}`)
                                }
                              } else {
                                setModalContentByType('tariffDown', plan)
                                if (userType !== 'internal') {
                                  sendUserClickEvent(`tariffs_click_license_${plan.code}`)
                                }
                              }
                            }}
                          >
                            {!isPreSelection && billingSelectedDealer?.plan.plan_id !== plan.id ? (
                              <span>
                                {preSelectedTariffs[TariffsType.PLAN_ID] === plan.id ? 'Выбрано' : 'Подключить'}
                              </span>
                            ) : (
                              <span>{billingSelectedDealer?.plan.plan_id === plan.id ? 'Активен' : 'Подключить'}</span>
                            )}
                          </Button>
                        </TariffsCard>
                      )
                    })}
                  </Slider>
                ) : null}
              </CardsContainer>
              <TogglesContainer>
                {modifiedClientPlansFeatures?.map((plan: any, index: number) => (
                  <ToggleBlock
                    data-index={currentTariffsSliderIndex}
                    style={{ display: isShowedTariffs ? 'block' : 'none' }}
                  >
                    <Toggle
                      list={plan.description}
                      title={plan.name}
                      key={plan.id}
                      children={
                        <div className="tariffs-slider">
                          {modifiedClientPlans?.length ? (
                            modifiedClientPlans[currentTariffsSliderIndex]?.find((el: any) => el.id === plan.id) ? (
                              <ToggleMath
                                className={
                                  modifiedClientPlans[currentTariffsSliderIndex]?.find((el: any) => el.id === plan.id)
                                    .feature_description
                                    ? 'text'
                                    : 'plus'
                                }
                              >
                                {wrapInSpans(
                                  modifiedClientPlans[currentTariffsSliderIndex]?.find((el: any) => el.id === plan.id)
                                    .feature_description
                                )}
                              </ToggleMath>
                            ) : (
                              <ToggleMath className="minus"></ToggleMath>
                            )
                          ) : null}
                        </div>
                      }
                    ></Toggle>
                  </ToggleBlock>
                ))}
                {!modifiedClientPlans.length ? (
                  <ToggleMore onClick={() => getMoreTariffs()} className={`${isShowedTariffs ? 'rotate' : ''}`}>
                    {isShowedTariffs ? 'Свернуть описание' : 'Развернуть описание'}
                    <img src={Arrow} alt="" />
                  </ToggleMore>
                ) : (
                  <ToggleMore
                    className={`${isShowedTariffs ? 'rotate' : ''}`}
                    onClick={() => {
                      setShowedTariffs(!isShowedTariffs)
                    }}
                  >
                    {isShowedTariffs ? 'Свернуть описание' : 'Развернуть описание'}
                    <img src={Arrow} alt="" />
                  </ToggleMore>
                )}
              </TogglesContainer>
            </div>
          ) : (
            <TariffsDesktop>
              <CardsContainer className="cards-desktop">
                {clientPlans?.map((plan: any, i: number) => {
                  if (billingSelectedDealer?.plan.plan_id === plan.id && activeIndexTariffPlan !== i) {
                    setActiveIndexTariffPlan(i)
                  }

                  return (
                    <TariffsCard className="plan" bgImage={tariffsIcons[i]} key={plan.id}>
                      <SubTitle>
                        {plan.name}
                        <TooltipGroup>
                          <p>{plan.description}</p>
                          <TooltipGroup>
                            <p>{plan.description}</p>
                            <img src={infoIcon} alt="" />
                          </TooltipGroup>
                        </TooltipGroup>
                      </SubTitle>
                      {billingSelectedDealer?.plan.plan_id === plan.id ? (
                        <TextPrice className="tariffs-text">
                          {Number(plan.price_per_period).toLocaleString('ru')} <BiRuble />
                        </TextPrice>
                      ) : null}
                      {plan.price_per_period > clientPlans[activeIndexTariffPlan]?.price_per_period &&
                      clientPlans[activeIndexTariffPlan]?.id === billingSelectedDealer?.plan.plan_id ? (
                        <TextPrice className="tariffs-text">
                          +
                          {Number(
                            plan.price_per_period - clientPlans[activeIndexTariffPlan]?.price_per_period
                          ).toLocaleString('ru')}{' '}
                          <BiRuble />
                        </TextPrice>
                      ) : null}
                      <Button
                        variant={billingSelectedDealer?.plan.plan_id === plan.id ? 'default' : 'secondary'}
                        size="medium"
                        style={{ pointerEvents: billingSelectedDealer?.plan.plan_id === plan.id ? 'none' : 'inherit' }}
                        onClick={() => {
                          if (!isPreSelection) {
                            handlerSetPreselectedTariffs(TariffsType.PLAN_ID, plan.id)
                            if (userType !== 'internal') {
                              sendUserClickEvent(`tariffs_click_license_preselection${plan.code}`)
                            }
                          } else if (
                            plan.price_per_period > clientPlans[activeIndexTariffPlan]?.price_per_period &&
                            clientPlans[activeIndexTariffPlan]?.id === billingSelectedDealer?.plan.plan_id
                          ) {
                            setModalContentByType('tariff', plan)
                            if (userType !== 'internal') {
                              sendUserClickEvent(`tariffs_click_license_${plan.code}`)
                            }
                          } else {
                            setModalContentByType('tariffDown', plan)
                            if (userType !== 'internal') {
                              sendUserClickEvent(`tariffs_click_license_${plan.code}`)
                            }
                          }
                        }}
                      >
                        {!isPreSelection && billingSelectedDealer?.plan.plan_id !== plan.id ? (
                          <span>{preSelectedTariffs[TariffsType.PLAN_ID] === plan.id ? 'Выбрано' : 'Подключить'}</span>
                        ) : (
                          <span>{billingSelectedDealer?.plan.plan_id === plan.id ? 'Активен' : 'Подключить'}</span>
                        )}
                      </Button>
                    </TariffsCard>
                  )
                })}
              </CardsContainer>
              <TogglesContainer>
                {modifiedClientPlansFeatures?.map((plan: any, index: number) => (
                  <ToggleBlock
                    className={clientPlans?.length >= 3 ? 'favourite' : ''}
                    style={{ display: isShowedTariffs ? 'block' : 'none' }}
                  >
                    <Toggle
                      list={plan.description}
                      title={plan.name}
                      key={plan.id}
                      children={
                        <div className="tariffs-slider">
                          {modifiedClientPlans?.map((client: any) =>
                            client?.find((el: any) => el.id === plan.id) ? (
                              <ToggleMath
                                className={
                                  client?.find((el: any) => el.id === plan.id).feature_description ? 'text' : 'plus'
                                }
                              >
                                {wrapInSpans(client?.find((el: any) => el.id === plan.id).feature_description)}
                              </ToggleMath>
                            ) : (
                              <ToggleMath className="minus"></ToggleMath>
                            )
                          )}
                        </div>
                      }
                    ></Toggle>
                  </ToggleBlock>
                ))}
                {!modifiedClientPlans.length ? (
                  <ToggleMore onClick={() => getMoreTariffs()} className={`${isShowedTariffs ? 'rotate' : ''}`}>
                    {isShowedTariffs ? 'Свернуть описание' : 'Развернуть описание'}
                    <img src={Arrow} alt="" />
                  </ToggleMore>
                ) : (
                  <ToggleMore
                    className={`${isShowedTariffs ? 'rotate' : ''}`}
                    onClick={() => {
                      setShowedTariffs(!isShowedTariffs)
                    }}
                  >
                    {isShowedTariffs ? 'Свернуть описание' : 'Развернуть описание'}
                    <img src={Arrow} alt="" />
                  </ToggleMore>
                )}
              </TogglesContainer>
            </TariffsDesktop>
          )}
        </div>
      ) : null}

      {addonPackages?.length ? (
        <LicenseContainer>
          <TariffsTitle>Дополнительные пакеты лицензий</TariffsTitle>

          {isMobile ? (
            <LicenseGroup>
              {addonPackages?.length ? (
                <Slider {...settingsAddons}>
                  {addonPackages?.map((pack: any) => (
                    <TariffsCard className="license" key={pack.id}>
                      <SubTitle className="license-title short-title">
                        {pack.name}
                        <TooltipGroup className="inline">
                          <p>{pack.description}</p>
                          <img src={infoIcon} alt="" />
                        </TooltipGroup>
                      </SubTitle>
                      <SmallText>стоимость за 1 период</SmallText>
                      <TextPrice className="license-text">
                        {Number(pack.price_per_period).toLocaleString('ru')}&nbsp;
                        <BiRuble />
                      </TextPrice>
                      <SmallText>
                        *стоимость подключения {Number(pack.price_per_connection).toLocaleString('ru')}&nbsp;
                        <BiRuble />
                      </SmallText>
                      <Button
                        variant={
                          !!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)
                            ? 'default'
                            : 'secondary'
                        }
                        size="medium"
                        onClick={() => {
                          if (!isPreSelection) {
                            handlerSetPreselectedTariffs(TariffsType.ADDON_PACKAGE_IDS, pack.id)
                            if (userType !== 'internal') {
                              sendUserClickEvent(`tariffs_click_addons_preselection_${pack.code}`)
                            }
                          } else if (
                            !!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)
                          ) {
                            setModalContentByType('activeLicense', pack)
                          } else {
                            if (!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)) {
                              if (userType !== 'internal') {
                                sendUserClickEvent(`tariffs_click_addons_${pack.code}`)
                              }
                              setModalContentByType('license', pack)
                            }
                          }
                        }}
                      >
                        {!!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)
                          ? 'Активен'
                          : 'Подключить'}
                      </Button>
                    </TariffsCard>
                  ))}
                </Slider>
              ) : null}
            </LicenseGroup>
          ) : (
            <LicenseGroup>
              {addonPackages?.map((pack: any) => (
                <TariffsCard className="license" key={pack.id}>
                  <SubTitle className="license-title short-title">
                    {pack.name}&nbsp;
                    <TooltipGroup className="inline">
                      <p>{pack.description}</p>
                      <img src={infoIcon} alt="" />
                    </TooltipGroup>
                  </SubTitle>
                  <SmallText>стоимость за 1 период</SmallText>
                  <TextPrice className="license-text">
                    {Number(pack.price_per_period).toLocaleString('ru')}&nbsp;
                    <BiRuble />
                  </TextPrice>
                  <SmallText>
                    *стоимость подключения {Number(pack.price_per_connection).toLocaleString('ru')}&nbsp;
                    <BiRuble />
                  </SmallText>
                  <Button
                    variant={
                      !!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)
                        ? 'default'
                        : 'secondary'
                    }
                    size="medium"
                    onClick={() => {
                      if (!isPreSelection) {
                        handlerSetPreselectedTariffs(TariffsType.ADDON_PACKAGE_IDS, pack.id)
                        if (userType !== 'internal') {
                          sendUserClickEvent(`tariffs_click_addons_preselection_${pack.code}`)
                        }
                      } else if (!!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)) {
                        setModalContentByType('activeLicense', pack)
                      } else {
                        if (!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)) {
                          if (userType !== 'internal') {
                            sendUserClickEvent(`tariffs_click_addons_${pack.code}`)
                          }
                          setModalContentByType('license', pack)
                        }
                      }
                    }}
                  >
                    {!isPreSelection &&
                    !billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id) ? (
                      <span>
                        {!!preSelectedTariffs[TariffsType.ADDON_PACKAGE_IDS]?.find((addon: any) => addon === pack.id)
                          ? 'Выбрано'
                          : 'Подключить'}
                      </span>
                    ) : (
                      <span>
                        {!!billingSelectedDealer?.plan.addon_package_ids?.find((addon) => addon === pack.id)
                          ? 'Активен'
                          : 'Подключить'}
                      </span>
                    )}
                  </Button>
                </TariffsCard>
              ))}
            </LicenseGroup>
          )}
        </LicenseContainer>
      ) : null}

      {supportPlans?.length ? (
        <PacksContainer>
          <TariffsTitle>Тарифы на контентные работы</TariffsTitle>

          {isMobile ? (
            <div className="support-mobile">
              {supportPlans?.length ? (
                <Slider {...settingsSupport}>
                  {supportPlans?.map((plan: any, i: number) => {
                    if (billingSelectedDealer?.plan.support_plan_id === plan.id && activeIndexSupportPlan !== i) {
                      setActiveIndexSupportPlan(i)
                    }

                    return (
                      <TariffsCard className="packs-card" key={plan.id}>
                        <SubTitle>{plan.name}</SubTitle>
                        <SmallText className="packs-info-text">стоимость за 1 период</SmallText>
                        <TextPrice className="packs-text">
                          {Number(plan.price_per_period).toLocaleString('ru')} <BiRuble />
                        </TextPrice>
                        <Button
                          variant={billingSelectedDealer?.plan.support_plan_id === plan.id ? 'default' : 'secondary'}
                          size="medium"
                          style={{
                            pointerEvents: billingSelectedDealer?.plan.support_plan_id === plan.id ? 'none' : 'inherit'
                          }}
                          onClick={() => {
                            if (!isPreSelection) {
                              handlerSetPreselectedTariffs(TariffsType.SUPPORT_PLAN_ID, plan.id)
                              if (userType !== 'internal') {
                                sendUserClickEvent(`tariffs_click_content_preselection_${plan.code}`)
                              }
                            } else {
                              setModalContentByType('support', plan)
                              if (userType !== 'internal') {
                                sendUserClickEvent(`tariffs_click_content_${plan.code}`)
                              }
                            }
                          }}
                        >
                          {!isPreSelection && billingSelectedDealer?.plan.support_plan_id !== plan.id ? (
                            <span>
                              {preSelectedTariffs[TariffsType.SUPPORT_PLAN_ID] === plan.id ? 'Выбрано' : 'Подключить'}
                            </span>
                          ) : (
                            <span>
                              {billingSelectedDealer?.plan.support_plan_id === plan.id ? 'Активен' : 'Подключить'}
                            </span>
                          )}
                        </Button>
                      </TariffsCard>
                    )
                  })}
                </Slider>
              ) : null}

              {modifiedSupportPlans?.length
                ? modifiedSupportPlans[0].map((plan: any, index: number) => (
                    <PacksPriceGroup style={{ display: isShowedSupportPlans ? 'block' : 'none' }}>
                      <MediumTitle>{plan.name}</MediumTitle>

                      <PacksPrices>
                        <PacksPrice>{modifiedSupportPlans[currentSupportSliderIndex][index].value}</PacksPrice>
                      </PacksPrices>
                    </PacksPriceGroup>
                  ))
                : null}

              <ToggleMore
                className={`seo-more ${isShowedSupportPlans ? 'rotate' : ''}`}
                onClick={() => {
                  if (userType !== 'internal') {
                    sendUserClickEvent(`tariffs_click_more_content`)
                  }
                  setShowedSupportPlans(!isShowedSupportPlans)
                }}
                style={{ marginTop: '16px' }}
              >
                {isShowedSupportPlans ? 'Свернуть описание' : 'Развернуть описание'}
                <img src={Arrow} alt="" />
              </ToggleMore>
            </div>
          ) : (
            <SupportDesktop>
              <FullContainer>
                <PacksGroup className="packs-group">
                  {supportPlans?.map((plan: any, i: number) => {
                    if (billingSelectedDealer?.plan.support_plan_id === plan.id && activeIndexSupportPlan !== i) {
                      setActiveIndexSupportPlan(i)
                    }

                    return (
                      <TariffsCard className="packs-card" key={plan.id}>
                        <SubTitle>{plan.name}</SubTitle>
                        <SmallText className="packs-info-text">стоимость за 1 период</SmallText>
                        <TextPrice className="packs-text">
                          {Number(plan.price_per_period).toLocaleString('ru')} <BiRuble />
                        </TextPrice>
                        <Button
                          variant={billingSelectedDealer?.plan.support_plan_id === plan.id ? 'default' : 'secondary'}
                          size="medium"
                          style={{
                            pointerEvents: billingSelectedDealer?.plan.support_plan_id === plan.id ? 'none' : 'inherit'
                          }}
                          onClick={() => {
                            if (!isPreSelection) {
                              handlerSetPreselectedTariffs(TariffsType.SUPPORT_PLAN_ID, plan.id)
                              if (userType !== 'internal') {
                                sendUserClickEvent(`tariffs_click_content_preselection_${plan.code}`)
                              }
                            } else {
                              setModalContentByType('support', plan)
                              if (userType !== 'internal') {
                                sendUserClickEvent(`tariffs_click_content_${plan.code}`)
                              }
                            }
                          }}
                        >
                          {!isPreSelection && billingSelectedDealer?.plan.support_plan_id !== plan.id ? (
                            <span>
                              {preSelectedTariffs[TariffsType.SUPPORT_PLAN_ID] === plan.id ? 'Выбрано' : 'Подключить'}
                            </span>
                          ) : (
                            <span>
                              {billingSelectedDealer?.plan.support_plan_id === plan.id ? 'Активен' : 'Подключить'}
                            </span>
                          )}
                        </Button>
                      </TariffsCard>
                    )
                  })}
                </PacksGroup>

                {modifiedSupportPlans?.length
                  ? modifiedSupportPlans[0].map((plan: any, index: number) => (
                      <PacksPriceGroup style={{ display: isShowedSupportPlans ? 'block' : 'none' }}>
                        <MediumTitle>{plan.name}</MediumTitle>

                        <PacksPrices>
                          {modifiedSupportPlans?.map((el: any) => (
                            <PacksPrice>{el[index].value}</PacksPrice>
                          ))}
                        </PacksPrices>
                      </PacksPriceGroup>
                    ))
                  : null}
              </FullContainer>
              <ToggleMore
                className={`seo-more ${isShowedSupportPlans ? 'rotate' : ''}`}
                onClick={() => {
                  if (userType !== 'internal') {
                    sendUserClickEvent(`tariffs_click_more_content`)
                  }
                  setShowedSupportPlans(!isShowedSupportPlans)
                }}
              >
                {isShowedSupportPlans ? 'Свернуть описание' : 'Развернуть описание'}
                <img src={Arrow} alt="" />
              </ToggleMore>
            </SupportDesktop>
          )}
        </PacksContainer>
      ) : null}

      {seoPlans?.length ? (
        <SeoContainer>
          <TariffsTitle>Тарифы услуг SEO</TariffsTitle>

          {isMobile ? (
            <div>
              <SeoGroup>
                {seoPlans?.length ? (
                  <Slider {...settingsSEO}>
                    {seoPlans?.map((plan: any, i: number) => {
                      if (billingSelectedDealer?.plan.seo_plan_id === plan.id && activeIndexSeoPlan !== i) {
                        setActiveIndexSeoPlan(i)
                      }

                      return (
                        <TariffsCard key={plan.id}>
                          <SubTitle>{plan.name}</SubTitle>
                          <TextPrice className="tariffs-text">
                            {Number(plan.price_per_period).toLocaleString('ru')} <BiRuble />
                          </TextPrice>
                          <Button
                            variant={billingSelectedDealer?.plan.seo_plan_id === plan.id ? 'default' : 'secondary'}
                            size="medium"
                            style={{
                              pointerEvents: billingSelectedDealer?.plan.seo_plan_id === plan.id ? 'none' : 'inherit'
                            }}
                            onClick={() => {
                              if (!isPreSelection) {
                                handlerSetPreselectedTariffs(TariffsType.SEO_PLAN_ID, plan.id)
                                if (userType !== 'internal') {
                                  sendUserClickEvent(`tariffs_click_seo_preselection_${plan.code}`)
                                }
                              } else {
                                setModalContentByType('seo', plan)
                                if (userType !== 'internal') {
                                  sendUserClickEvent(`tariffs_click_seo_${plan.code}`)
                                }
                              }
                            }}
                          >
                            {!isPreSelection && billingSelectedDealer?.plan.seo_plan_id !== plan.id ? (
                              <span>
                                {preSelectedTariffs[TariffsType.SEO_PLAN_ID] === plan.id ? 'Выбрано' : 'Подключить'}
                              </span>
                            ) : (
                              <span>
                                {billingSelectedDealer?.plan.seo_plan_id === plan.id ? 'Активен' : 'Подключить'}
                              </span>
                            )}
                          </Button>
                        </TariffsCard>
                      )
                    })}
                  </Slider>
                ) : null}
              </SeoGroup>

              {modifiedSeoPlans?.length
                ? modifiedSeoPlans[0].map((plan: any, index: number) => (
                    <SeoInfo style={{ display: isShowedSeoPlans ? 'block' : 'none' }}>
                      <SeoInfoGroup>
                        <MediumTitle>{plan.name}</MediumTitle>

                        <PacksPrices className="packs-seo">
                          <PacksPrice>{modifiedSeoPlans[currentSeoSliderIndex][index].value}</PacksPrice>
                        </PacksPrices>
                      </SeoInfoGroup>
                    </SeoInfo>
                  ))
                : null}

              <ToggleMore
                className={`seo-more ${isShowedSeoPlans ? 'rotate' : ''}`}
                onClick={() => {
                  if (userType !== 'internal') {
                    sendUserClickEvent('tariffs_click_more_seo')
                  }
                  setShowedSeoPlans(!isShowedSeoPlans)
                }}
              >
                {isShowedSeoPlans ? 'Свернуть описание' : 'Развернуть описание'}
                <img src={Arrow} alt="" />
              </ToggleMore>
            </div>
          ) : (
            <SeoDesktop>
              <SeoGroup>
                {seoPlans?.map((plan: any, i: number) => {
                  if (billingSelectedDealer?.plan.seo_plan_id === plan.id && activeIndexSeoPlan !== i) {
                    setActiveIndexSeoPlan(i)
                  }

                  return (
                    <TariffsCard key={plan.id} className={i > 3 ? 'hide' : ''}>
                      <SubTitle>{plan.name}</SubTitle>
                      <TextPrice className="tariffs-text">
                        {Number(plan.price_per_period).toLocaleString('ru')} <BiRuble />
                      </TextPrice>
                      <Button
                        variant={billingSelectedDealer?.plan.seo_plan_id === plan.id ? 'default' : 'secondary'}
                        size="medium"
                        style={{
                          pointerEvents: billingSelectedDealer?.plan.seo_plan_id === plan.id ? 'none' : 'inherit'
                        }}
                        onClick={() => {
                          if (!isPreSelection) {
                            handlerSetPreselectedTariffs(TariffsType.SEO_PLAN_ID, plan.id)
                            if (userType !== 'internal') {
                              sendUserClickEvent(`tariffs_click_seo_preselection_${plan.code}`)
                            }
                          } else {
                            setModalContentByType('seo', plan)
                            if (userType !== 'internal') {
                              sendUserClickEvent(`tariffs_click_seo_${plan.code}`)
                            }
                          }
                        }}
                      >
                        {!isPreSelection && billingSelectedDealer?.plan.seo_plan_id !== plan.id ? (
                          <span>
                            {preSelectedTariffs[TariffsType.SEO_PLAN_ID] === plan.id ? 'Выбрано' : 'Подключить'}
                          </span>
                        ) : (
                          <span>{billingSelectedDealer?.plan.seo_plan_id === plan.id ? 'Активен' : 'Подключить'}</span>
                        )}
                      </Button>
                    </TariffsCard>
                  )
                })}
              </SeoGroup>

              {modifiedSeoPlans?.length
                ? modifiedSeoPlans[0].map((plan: any, index: number) => (
                    <SeoInfo style={{ display: isShowedSeoPlans ? 'block' : 'none' }}>
                      <SeoInfoGroup>
                        <MediumTitle>{plan.name}</MediumTitle>

                        <PacksPrices className="packs-seo">
                          {modifiedSeoPlans?.map((el: any, i: number) => (
                            <PacksPrice className={i > 3 ? 'hide' : ''}>{el[index].value}</PacksPrice>
                          ))}
                        </PacksPrices>
                      </SeoInfoGroup>
                    </SeoInfo>
                  ))
                : null}
              <ToggleMore
                className={`seo-more ${isShowedSeoPlans ? 'rotate' : ''}`}
                onClick={() => {
                  if (userType !== 'internal') {
                    sendUserClickEvent('tariffs_click_more_seo')
                  }
                  setShowedSeoPlans(!isShowedSeoPlans)
                }}
              >
                {isShowedSeoPlans ? 'Свернуть описание' : 'Развернуть описание'}
                <img src={Arrow} alt="" />
              </ToggleMore>
            </SeoDesktop>
          )}
        </SeoContainer>
      ) : null}
      {isPopupOpen ? (
        <Popup
          closePopup={setPopupOpen}
          background={true}
          isDanger={modalType === 'archive'}
          isCloseHidden={modalType === 'archive'}
          className={modalType === 'archive' ? 'archive-modal' : ''}
        >
          {
            {
              tariff: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Вы переходите на тариф {activeModalElement?.name}
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Оплата для нового тарифа составляет&nbsp;
                    {Number(activeModalElement?.price_per_period).toLocaleString('ru')} <BiRuble /> за период. Изменения
                    вступят в силу после завершения текущего периода. <br />
                    <br />
                    Спасибо, что вы с нами!
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isLoadingNewService}
                    onClick={() => {
                      setService('plan', activeModalElement?.id)
                    }}
                  >
                    Подключить
                  </Button>
                  <Text fontSize="12px" lineHeight="16px" textAlign="center" color="#232323">
                    Нажимая кнопку подключить вы даете согласие на применение описанных услуг к вашему лицевому счету.
                  </Text>
                </div>
              ),
              tariffDown: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Переход на тариф {activeModalElement?.name}
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Для перехода на тариф {activeModalElement?.name} необходимо связаться с вашим аккаунт-менеджером.{' '}
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isLoadingNewService}
                    onClick={() => {
                      setService('plan', activeModalElement?.id)
                    }}
                  >
                    Отправить запрос
                  </Button>
                </div>
              ),
              support: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Вы переходите на тариф {activeModalElement?.name}
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Стоимость нового тарифа составляет{' '}
                    {Number(activeModalElement?.price_per_period).toLocaleString('ru')} <BiRuble /> за период. Изменения
                    вступят в силу после завершения текущего периода. <br />
                    <br />
                    <br />
                    Спасибо, что вы с нами!
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isLoadingNewService}
                    onClick={() => {
                      setService('support_plan', activeModalElement?.id)
                    }}
                  >
                    Подключить
                  </Button>
                  <Text fontSize="12px" lineHeight="16px" textAlign="center" color="#232323">
                    Нажимая кнопку подключить вы даете согласие на применение описанных услуг к вашему лицевому счету.
                  </Text>
                </div>
              ),
              seo: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Вы переходите на тариф {activeModalElement?.name}
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Стоимость нового тарифа составляет{' '}
                    {Number(activeModalElement?.price_per_period).toLocaleString('ru')} <BiRuble /> за период. Изменения
                    вступят в силу после завершения текущего периода. <br />
                    <br />
                    Спасибо, что вы с нами!
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isLoadingNewService}
                    onClick={() => {
                      setService('seo_plan', activeModalElement?.id)
                    }}
                  >
                    Подключить
                  </Button>
                  <Text fontSize="12px" lineHeight="16px" textAlign="center" color="#232323">
                    Нажимая кнопку подключить вы даете согласие на применение описанных услуг к вашему лицевому счету.
                  </Text>
                </div>
              ),
              license: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Вы подключаете пакет {activeModalElement?.name}
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Стоимость подключаемого пакета составляет{' '}
                    {Number(activeModalElement?.price_per_period).toLocaleString('ru')} <BiRuble /> за период. Изменения
                    вступят в силу после завершения текущего периода. <br />
                    <br />
                    Спасибо, что вы с нами!
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isLoadingNewService}
                    onClick={() => {
                      setService('addon_package', activeModalElement?.id)
                    }}
                  >
                    Подключить
                  </Button>
                  <Text fontSize="12px" lineHeight="16px" textAlign="center" color="#232323">
                    Нажимая кнопку подключить вы даете согласие на применение описанных услуг к вашему лицевому счету.
                  </Text>
                </div>
              ),
              activeLicense: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Отключение пакета {activeModalElement?.name}
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Для отключения пакета {activeModalElement?.name} необходимо связаться с вашим аккаунт-менеджером.
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isLoadingNewService}
                    onClick={() => {
                      deactivateLicenseHandler(billingSelectedDealer?.id, activeModalElement?.id)
                    }}
                  >
                    Отправить запрос
                  </Button>
                </div>
              ),
              archive: (
                <div>
                  <TitlePopup size={'small'} textAlign="center">
                    Ваш тариф переходит в архив
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Мы обновляем наши тарифы, чтобы предоставить вам лучший опыт работы.
                    <br />
                    Подтвердите подключение предложенных ниже услуг или выберите свой набор самостоятельно.
                  </Text>
                  <Text fontSize="16px" lineHeight="22px">
                    <ArchiveGroup bold={true}>
                      <span>Тариф</span>
                      <span>Статус</span>
                    </ArchiveGroup>
                    <ArchiveGroup border={true}>
                      <span>
                        {clientPlans?.find((plan: any) => plan.id === preSelectedTariffs[TariffsType.PLAN_ID])?.name}
                      </span>
                      <span>Рекомендация</span>
                    </ArchiveGroup>
                    <ArchiveGroup bold={true} margin={true}>
                      <span>Пакеты</span>
                      <span>Статус</span>
                    </ArchiveGroup>
                    {preSelectedTariffs[TariffsType.ADDON_PACKAGE_IDS]?.map((pack: any) => {
                      return (
                        <ArchiveGroup border={true}>
                          <span>{addonPackages?.find((addon: any) => addon.id === pack)?.name}</span>
                          <span>Рекомендация</span>
                        </ArchiveGroup>
                      )
                    })}
                    <ArchiveGroup bold={true} margin={true}>
                      <span>Тариф Поддержки</span>
                      <span>Статус</span>
                    </ArchiveGroup>
                    <ArchiveGroup border={true}>
                      <span>
                        {
                          supportPlans?.find((plan: any) => plan.id === preSelectedTariffs[TariffsType.SUPPORT_PLAN_ID])
                            ?.name
                        }
                      </span>
                      <span>Рекомендация</span>
                    </ArchiveGroup>
                    <ArchiveGroup bold={true} margin={true}>
                      <span>Тариф SEO</span>
                      <span>Статус</span>
                    </ArchiveGroup>
                    <ArchiveGroup border={true}>
                      <span>
                        {seoPlans?.find((plan: any) => plan.id === preSelectedTariffs[TariffsType.SEO_PLAN_ID])?.name}
                      </span>
                      <span>Рекомендация</span>
                    </ArchiveGroup>
                    <br />
                    <br />
                    <p style={{ textAlign: 'center' }}>Спасибо, что вы с нами!</p>
                  </Text>
                  <ModalButtonGroup>
                    <Button
                      onClick={() => {
                        includeNewTariffs()
                      }}
                    >
                      Подключить
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setPopupOpen(false)
                      }}
                    >
                      Выбрать другой тариф
                    </Button>
                  </ModalButtonGroup>
                  <Text fontSize="12px" lineHeight="16px" textAlign="center" color="#232323">
                    Нажимая кнопку подключить вы даете согласие на применение описанных услуг к вашему лицевому счету.
                  </Text>
                </div>
              ),
              success: (
                <div>
                  <Text textAlign="center">
                    <img className="success-check" src={SuccessCheck} alt="" />
                  </Text>
                  <TitlePopup size={'small'} textAlign="center">
                    Заявка на изменение тарифа получена
                  </TitlePopup>
                  <Text fontSize="16px" lineHeight="22px" textAlign="center">
                    Тариф будет изменен в следующем периоде. В случае необходимости с вами свяжется аккаунт менеджер.
                    <br />
                    <br />
                  </Text>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    onClick={() => {
                      setPopupOpen(false)
                    }}
                  >
                    Закрыть
                  </Button>
                </div>
              )
            }[modalType]
          }
        </Popup>
      ) : null}

      {!isPreSelection ? (
        <PreSelectBlock style={{ marginTop: '60px', marginBottom: '0' }}>
          Подтвердите подключение предложенных выше услуг
          <Button
            style={{ borderColor: '#F18801', background: '#F18801', paddingTop: '5px', paddingBottom: '5px' }}
            onClick={() => setModalContentByType('archive')}
          >
            Подключить
          </Button>
        </PreSelectBlock>
      ) : null}
    </Container>
  )
}
