//@ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiRuble } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'

import { usePostInvoicingMutation } from '../../api/billingUserApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import { Fields } from '../../pages/registartion/style'
import { selectUserBilling, setStatus } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { Input } from '../../ui/input'
import { Notification } from '../../ui/notification'
import { Warning } from '../../ui/warning/Warning'
import { addNDS } from '../../utils/addNDS'
import { calcNDSSum } from '../../utils/calcNDSSum'
import { calcPeriodPaymentAmount } from '../../utils/calcPeriodPaymentAmount'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { numberWithSpaces } from '../../utils/numberWithSpaces'
import { invoicingSchema } from '../../validation/invoicingSchema'
import markGrey from './../../assets/icons/markGrey.svg'
import {
  Block,
  BtnWrap,
  Flex,
  Icon,
  InvoicingForm,
  InvoicingInner,
  Item,
  Label,
  PeriodSelector,
  PeriodSelectorButton,
  Text,
  Title
} from './style'

const periodTypes = [3, 1]

export const Invoicing: FC<any> = ({ billingClientInfoById, setIsInvoicingSuccess, setIsInvoicing, period = 1 }) => {
  const [activePeriod, setActivePeriod] = useState(periodTypes[period])
  const payment = Number(billingClientInfoById?.balance?.payment)
  const total = Number(billingClientInfoById?.balance?.total)
  const percentNDS = Number(billingClientInfoById?.balance?.percent_nds ?? 0)
  const dispatch = useDispatch()
  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const { billingClientInfo } = useSelector(selectUserBilling)

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue
  } = useForm({
    mode: 'all',
    resolver: yupResolver(invoicingSchema),
    defaultValues: {
      sum: calcPeriodPaymentAmount(total, payment, activePeriod, percentNDS)
    }
  })

  const userType = getLocalStorageValue('user')?.attributes?.type

  const changeActivePeriod = (type: number) => {
    setActivePeriod(type)
    setValue('sum', calcPeriodPaymentAmount(total, payment, type, percentNDS))
  }

  const sum = watch('sum')
  const totalPay = addNDS(sum, percentNDS)

  const { billingSelectedDealerId } = useSelector(selectUserBilling)

  const onSubmit = async () => {
    if (billingSelectedDealerId) {
      await postInvoicing({
        id: billingSelectedDealerId,
        body: {
          amount: totalPay
        }
      })
    }
    setIsInvoicing(false)
  }

  const [
    postInvoicing,
    {
      isLoading: invoicingIsLoading,
      data: invoicingData,
      isError: invoicingIsError,
      error: invoicingError,
      isSuccess: invoicingIsSuccess
    }
  ] = usePostInvoicingMutation()

  useEffect(() => {
    if (invoicingIsSuccess || invoicingIsError) {
      setIsInvoicingSuccess(true)
      if (invoicingIsSuccess) {
        dispatch(setStatus('success'))
      } else {
        dispatch(setStatus('error'))
      }
    }
  }, [invoicingIsSuccess, invoicingIsError, setIsInvoicingSuccess, dispatch])

  return (
    <InvoicingInner>
      <Title>Создать счет на оплату</Title>
      <Notification icon={markGrey} className={'full'}>
        Напоминаем вам, что наши сервисы работают по схеме предварительной оплаты в автоматическом режиме. Во избежание
        отключения инструментов рекомендуем пополнять счёт на 2 и более периода вперёд.
      </Notification>
      <PeriodSelector>
        <PeriodSelectorButton
          key={periodTypes[0]}
          onClick={() => changeActivePeriod(periodTypes[0])}
          variant={activePeriod === periodTypes[0] ? 'default' : 'secondary'}
        >
          За три периода
        </PeriodSelectorButton>

        <PeriodSelectorButton
          key={periodTypes[1]}
          onClick={() => changeActivePeriod(periodTypes[1])}
          variant={activePeriod === periodTypes[1] ? 'default' : 'secondary'}
        >
          За один период
        </PeriodSelectorButton>
      </PeriodSelector>
      <Block>
        <Item>
          <Flex>
            {total ? numberWithSpaces(total?.toFixed(2)).toLocaleString() : '0.00'}
            <BiRuble />
          </Flex>
          <Text>Текущий баланс на вашем лицевом счете</Text>
        </Item>
        <Item>
          <Flex>
            {payment ? numberWithSpaces(payment?.toFixed(2)).toLocaleString() : '-'}
            {payment ? <BiRuble /> : null}
          </Flex>
          <Text>
            Платеж за период
            <br /> (без учета НДС)
          </Text>
        </Item>
      </Block>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InvoicingForm>
          <Label>
            <Flex>
              Сумма пополнения,
              <Icon>
                <BiRuble />
              </Icon>
            </Flex>
          </Label>
          <Fields className="margin">
            <Input type="text" register={register} name={'sum'} required placeholder={'Введите сумму'} />
            {errors?.sum && <Warning status={'error'}>{errors?.sum?.message}</Warning>}
          </Fields>
          <Text>
            <Flex>
              {`После пополнения баланс составит: ${
                numberWithSpaces((total + totalPay)?.toFixed(2)).toLocaleString()
                // total && totalPay ? numberWithSpaces((total + totalPay)?.toFixed(2)).toLocaleString() : '-'
              }`}{' '}
              <BiRuble />
              {/* {total && totalPay.toFixed(2) ? <BiRuble /> : null} */}
            </Flex>
          </Text>
          <Block>
            <Item>
              <Flex>
                {` ${sum ? numberWithSpaces(calcNDSSum(sum, percentNDS).toFixed(2)).toLocaleString() : '-'}`}
                {sum ? <BiRuble /> : null}
              </Flex>
              <Text>Сумма НДС ({percentNDS}%)</Text>
            </Item>
            <Item bold={true}>
              <Flex>
                {totalPay ? numberWithSpaces(totalPay?.toFixed(2)).toLocaleString() : '-'}
                {totalPay ? <BiRuble /> : null}
              </Flex>
              <Text>Всего к оплате</Text>
            </Item>
          </Block>
        </InvoicingForm>
        <BtnWrap>
          <Button
            onClick={() => {
              if (userType !== 'internal') {
                sendUserClickEvent('button_create_invoice_click')
              }
            }}
            disabled={invoicingIsLoading}
          >
            Создать счет
          </Button>
        </BtnWrap>
      </form>
    </InvoicingInner>
  )
}
