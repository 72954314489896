import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useGetBillingClientResponsibleUserByIdMutation } from '../../api/billingUserApi'
import { Container, GreyText } from '../../assets/styles/global'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Label, Position, User } from '../settings/headerSettings/style'
import { ContactLink, Field, HeaderContactsInner, Title, Wrap } from './style'

export const Contacts = () => {
  const [responsibleUser, setResponsibleUser] = useState<any>({})
  const { billingSelectedDealerId } = useSelector(selectUserBilling)

  const [
    getBillingClientResponsibleUserById,
    {
      isLoading: isLoadingBillingClientResponsibleUserById,
      data: billingClientResponsibleUserById,
      isError: isErrorBillingClientResponsibleUserById,
      error: billingClientResponsibleUserByIdError,
      isSuccess: isSuccessBillingClientResponsibleUserById
    }
  ] = useGetBillingClientResponsibleUserByIdMutation()

  useEffect(() => {
    ;(async () => {
      await getBillingClientResponsibleUserById({ id: billingSelectedDealerId || '' })
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    setResponsibleUser(billingClientResponsibleUserById)
  }, [billingClientResponsibleUserById])

  return (
    <Container>
      <Wrap>
        <Title>Контакты персонального менеджера</Title>
        <HeaderContactsInner>
          <Field>
            <User isBlack>{`${responsibleUser?.first_name || ''} ${responsibleUser?.last_name || ''}`}</User>
            <Position>Ваш менеджер</Position>
          </Field>
          <Field>
            <ContactLink href={`mailto:${responsibleUser?.email || ''}`}>{responsibleUser?.email || '-'}</ContactLink>
            <Label>E-mail</Label>
          </Field>
          <Field>
            <ContactLink>{responsibleUser?.work_phone || '-'}</ContactLink>
            <Label>Телефон</Label>
          </Field>
        </HeaderContactsInner>
      </Wrap>
      <Wrap bg={'white'}>
        <Title>Контакты компании UDP Auto</Title>
        <HeaderContactsInner>
          <Field>
            <User isBlack>ООО «Ю ДИ ПИ АВТО»</User>
            <Position>пн-пт с 8:00 – 20:00</Position>
          </Field>
          <Field>
            <ContactLink href={`mailto:info@udpauto.ru`}>info@udpauto.ru</ContactLink>
            <Label>E-mail</Label>
          </Field>
          <Field>
            <ContactLink href={`tel:+78004440734`}>+7 (800) 444-07-34</ContactLink>
            <Label>Телефон</Label>
          </Field>
        </HeaderContactsInner>
      </Wrap>
      <Wrap bg={'white'}>
        <Title>Контакты поддержки клиентов</Title>
        <HeaderContactsInner>
          <Field>
            <User isBlack>Клиентская поддержка UDP Auto</User>
            <Position>пн-пт с 8:00 – 20:00</Position>
          </Field>
          <Field>
            <ContactLink href={`mailto:support@udpauto.ru`}>support@udpauto.ru</ContactLink>
            <Label>E-mail</Label>
          </Field>
          <Field>
            <ContactLink href={`tel:+78004440734`}>
              +7 (800) 444-07-34, <span>доб. 3</span>
            </ContactLink>
            <Label>Телефон</Label>
          </Field>
        </HeaderContactsInner>
      </Wrap>
    </Container>
  )
}
