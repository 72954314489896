import './index.css'

import { Global, Theme, ThemeProvider } from '@emotion/react/macro'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { globalStyle } from './assets/styles/global'
import { baseTheme } from './assets/styles/theme'
import { AuthProvider } from './hooks/useAuth'
import reportWebVitals from './reportWebVitals'
import store from './store/store'

const theme: Theme = baseTheme

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <Global styles={globalStyle} />
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
