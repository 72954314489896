import { createApi, FetchArgs } from '@reduxjs/toolkit/query/react'

import { getLocalStorageValue } from '../utils/localStorageHelpers'
import { customFetchBaseWithReauth } from '.'

interface ClickEventPayload {
  data: {
    attributes: {
      type: 'click'
      payload: {
        target: string
        client_id: string
      }
    }
  }
}

interface ClickEventResponse {
  success: boolean
  message?: string
}

export const userEventsApi = createApi({
  reducerPath: 'userEventsApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth(
      {
        ...args,
        baseUrl: process.env.REACT_APP_API_URL
      },
      api,
      extraOptions
    ),
  endpoints: (builder) => ({
    sendUserClickEvent: builder.mutation<ClickEventResponse, string>({
      query: (target: string): FetchArgs | null => {
        const userType = getLocalStorageValue('user')?.attributes?.type
        return {
          url: '/user_events/push',
          method: 'POST',
          body: {
            data: {
              attributes: {
                type: 'click',
                payload: {
                  target,
                  client_id: `${getLocalStorageValue('billingSelectedDealerId')}`
                }
              }
            }
          } as ClickEventPayload
        }
      }
    })
  })
})

export const { useSendUserClickEventMutation } = userEventsApi
