import styled from '@emotion/styled/macro'

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &.archive-modal > div {
    max-height: 90vh;
    overflow-y: scroll;
  }
`

export const ModalInner = styled.div<{ background: boolean; textAlign?: string }>`
  width: 45.6rem;
  max-height: 90%;
  background: ${({ theme, background }) => (background ? theme.colors.grey1 : theme.colors.white)};
  border-radius: 16px;
  padding: 40px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: ${({ textAlign }) => textAlign === 'center' && 'center'};
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  @media ${({ theme }) => theme.media.medium} {
    padding: 30px 15px;
    width: calc(100% - 32px);
  }

  button {
    margin-bottom: 2.4rem;
    @media ${({ theme }) => theme.media.medium} {
      margin-bottom: 1.6rem;
    }
  }

  .full {
    margin: 0 -24px;
    border-radius: 0;
    margin-bottom: 15px;
  }
`

export const ModalDoubleInner = styled.div<{ background: boolean; textAlign?: string }>`
  width: 86.6rem;
  max-height: 54.3rem;
  background: ${({ theme, background }) => (background ? theme.colors.grey1 : theme.colors.white)};
  border-radius: 1.6rem;
  padding: 4rem 0 1.4rem 0;
  display: flex;
  text-align: ${({ textAlign }) => textAlign === 'center' && 'center'};
  position: relative;

  @media ${({ theme }) => theme.media.medium} {
    padding: 3rem 1.5rem;
    width: calc(100% - 3.2rem);
  }

  @media (max-height: 900px) {
    overflow: auto;
  }

  button {
    margin-bottom: 2.4rem;
    margin-top: auto;
    @media ${({ theme }) => theme.media.medium} {
      margin-bottom: 1.6rem;
    }
  }

  .full {
    margin: 0 -2.4rem;
    border-radius: 0;
    margin-bottom: 1.5rem;
  }
`

export const DoublePopupItem = styled.div<{ textAlign?: string; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 44.5rem;
  height: 48.9rem;
  padding: 0 4.5rem;
  text-align: ${({ textAlign }) => textAlign === 'center' && 'center'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled }) => disabled && '0.5'};
  position: relative;

  &[disabled] {
    opacity: 1;

    div {
      color: #888888;
      z-index: 2;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: calc(100% + 5.4rem);
      top: -4rem;
      background: #ffffff;
    }
  }
`

export const TitlePopup = styled.div<{ size?: string; textAlign?: string }>`
  font-size: ${({ size }) => (size === 'small' ? '2.4rem' : '2.8rem')};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  line-height: 3.6rem;
  font-weight: 700;
  font-family: 'Helvetica', sans-serif;
  margin-bottom: 1.5rem;
  width: 100%;
`

export const Text = styled.div<{ textAlign?: string; fontSize?: string; lineHeight?: string; color?: string }>`
  text-align: ${({ textAlign }) => textAlign && textAlign};
  ${({ theme }) => theme.body.p3};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.4rem')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '2rem')};
  color: ${({ color }) => (color ? color : '#000000')};
  margin-bottom: 3.2rem;

  span {
    display: inline;
  }
  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: underline;
    font-weight: 600;
  }

  .success-check {
    width: 80px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 0 12px;
  }
`

export const TextDoublePopup = styled.div`
  ${({ theme }) => theme.body.p3};
  span {
    display: inline;
  }
  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: underline;
    font-weight: 600;
  }
`

export const SubTextDoublePopup = styled.div`
  ${({ theme }) => theme.body.p5};
  margin-top: 2.4rem;
`

export const ImageModal = styled.div`
  margin-bottom: 6.2rem;
  img {
    display: block;
    max-width: 14.6rem;
    width: 100%;
    max-height: 15.4rem;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;

  @media ${({ theme }) => theme.media.medium} {
    top: 0.9rem;
    right: 0.9rem;
  }
`

export const Table = styled.div`
  width: 100%;
  ${({ theme }) => theme.body.p3};
  margin-bottom: 3rem;
  flex-grow: 1;
`

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 1.6rem;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  line-height: 2rem;
  padding-bottom: 0.7rem;
  margin-bottom: 1.5rem;
`

export const SumRow = styled.div`
  ${({ theme }) => theme.body.p2};
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  justify-content: space-between;
`

export const Total = styled.div`
  ${({ theme }) => theme.body.p2};
  font-weight: bold;
  display: flex;
  width: 100%;
  font-size: 2rem;
  margin-top: 5rem;
  justify-content: space-between;
`

export const TableText = styled.div`
  flex-grow: 1;
  max-width: 75%;
`

export const TablePrice = styled.div`
  display: flex;
  align-items: center;
`

export const List = styled.div`
  ${({ theme }) => theme.body.p3};
  list-style-type: none;

  li {
    margin-bottom: 1.5rem;

    span {
      font-weight: 700;
    }
  }
`
