import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseWithReauth } from '.'

export const tariffsApi = createApi({
  reducerPath: 'tariffsApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth(
      {
        ...args,
        baseUrl: process.env.REACT_APP_BILLING_API_URL
      },
      api,
      extraOptions
    ),
  endpoints: (builder) => ({
    getClientPlans: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/client/${id}/plans`,
        method: 'GET'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    getClientPlansFeatures: builder.mutation<any, { id: string | number; params?: string }>({
      query: ({ id, params }) => ({
        url: `/client/${id}/plans_features?${params ? params : 'page[number]=1&page[size]=3'}`,
        method: 'GET'
      })
      // transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    getAddonPackages: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/client/${id}/addon_packages`,
        method: 'GET'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    getSupportPlans: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/client/${id}/support_plans`,
        method: 'GET'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    getSeoPlans: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/client/${id}/seo_plans`,
        method: 'GET'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    setNewService: builder.mutation<any, { client_id: string | number; entity_type: string; entity_id: string }>({
      query: ({ client_id, entity_type, entity_id }) => ({
        url: `/client/${client_id}/request_plan/${entity_type}/${entity_id}/activate`,
        method: 'POST'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    acceptPlan: builder.mutation<any, { client_id: string | number; body: any }>({
      query: ({ client_id, body }) => ({
        url: `/client/${client_id}/accept_plan`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    }),
    deactivateLicense: builder.mutation<any, { client_id: string | number; entity_id: string }>({
      query: ({ client_id, entity_id }) => ({
        url: `/client/${client_id}/request_plan/addon_package/${entity_id}/deactivate`,
        method: 'POST'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    })
  })
})

export const {
  useGetClientPlansMutation,
  useGetClientPlansFeaturesMutation,
  useGetAddonPackagesMutation,
  useGetSupportPlansMutation,
  useGetSeoPlansMutation,
  useSetNewServiceMutation,
  useAcceptPlanMutation,
  useDeactivateLicenseMutation
} = tariffsApi
