import styled from '@emotion/styled/macro'

import { baseTheme } from '../../assets/styles/theme'
import { StyledButton } from '../../ui/button/style'

export const InvoicingInner = styled.div`
  text-align: left;

  .full {
    margin: 0 -2.4rem;
    padding: 1.6rem 1.6rem 1.6rem 5.6rem;
    margin-bottom: 2rem;

    img {
      left: 1.9rem;

      @media ${({ theme }) => theme.media.medium} {
        top: calc(50% - 1.2rem);
      }
    }

    @media ${({ theme }) => theme.media.medium} {
      margin: 0 -1.5rem 2.6rem;
      align-items: center;
    }
  }
`

export const Title = styled.div`
  font-size: 2.8rem;
  font-family: 'Helvetica', sans-serif;
  font-weight: 700;
  margin-bottom: 2.7rem;

  @media ${({ theme }) => theme.media.medium} {
    font-size: 2.4rem;
  }
`

export const Total = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  font-family: 'Helvetica', sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 0.9rem;
`

export const Text = styled.div`
  ${({ theme }) => theme.body.p3};
  color: ${({ theme }) => theme.colors.grey3};
  display: flex;
  flex-wrap: wrap;
`

export const Block = styled.div`
  display: flex;
  margin-top: 1.8rem;
  gap: 2.4rem;

  ${Text} {
    font-size: 1.4rem;
  }
`

export const InvoicingForm = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.6rem;
  margin-top: 2.8rem;
  margin-bottom: 3.2rem;

  input::placeholder {
    font-size: 1.8rem;
    color: #c4c4c4;
    font-weight: 300;
  }

  .margin {
    margin: 0 0 0.7rem 0;
  }

  ${Text} {
    color: #232323;
    font-size: 1.2rem;
  }

  @media ${({ theme }) => theme.media.medium} {
    margin: 2.8rem -1.4rem 3.2rem;
    border-radius: 0;
  }
`

export const Item = styled.div<{ bold?: boolean }>`
  ${({ theme }) => theme.body.p2};
  color: ${({ theme, bold }) => (bold ? theme.colors.basic : theme.colors.grey5)};
  max-width: 45%;
  width: 100%;
  font-weight: ${({ bold }) => bold && 700};

  ${Text} {
    margin-top: 0.4rem;
    color: ${({ theme }) => theme.colors.grey3} !important;
    font-size: 1.4rem !important;
  }
`

export const Label = styled.div`
  ${({ theme }) => theme.body.p3};
  margin-bottom: 1rem;
`

export const Icon = styled.div`
  display: inline-flex;
  vertical-align: middle;
  line-height: 2rem;
  align-items: center;
`

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
`

export const PeriodSelector = styled.div`
  display: flex;
  border: 1px solid ${baseTheme?.colors?.accent};
  border-radius: 1rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4rem;
`

export const PeriodSelectorButton = styled(StyledButton)`
  height: 100%;
  width: 100%;
  min-height: initial !important;
  margin-bottom: 0 !important;
  border: transparent;

  &:hover {
    border: transparent !important;
    opacity: ${(theme) => (theme.variant === 'secondary' ? 0.7 : 1)};
  }
`
